<template>
    <main>
      <slot/>
    </main>
</template>

<script>
export default {
  name: 'LayoutSnapshot'
};
</script>
